import { useEffect, useState } from "react";
import { storage } from "../firebaseConf";
import { ref, uploadBytesResumable, getDownloadURL, listAll, deleteObject } from "firebase/storage";
import { db } from "../firebaseConf";
import { collection, doc, getDocs, updateDoc, query, where, addDoc, deleteDoc} from "firebase/firestore";

function AdminGallery () {


    const [file, setFile] = useState("");
    const [percent, setPercent] = useState(null);
    const [picturesData, setPicturesData] = useState([])
    const [uploaded, setUploaded] = useState(false)
    const [videoArrState, setVideoArrState] = useState([])
    const [newPos, setNewPos] = useState()
    const [newVideoURL, setNewVideoURL] = useState("")
    const [newPosterURL, setNewPosterURL] = useState("")
    const [newVideoPos, setNewVideoPos] = useState()

    function handleFileChange(event) {
        setFile(event.target.files[0]);
    }
 
    const handleUpload = async () => {
        if (!file) {
            alert("Nincs kép!");
            return
        }

        if (file.size > 1.5 * 1024 * 1024) { // 1.5MB a kép max mérete, 1.5 változtandó ha növelni akarjuk
            alert("A kép nem lehet 1.5MB-nál nagyobb");
            return;
        }

        const storageRef = ref(storage, `/pictures/${file.name}`);
        const uploadTask =  uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setPercent(percent);
            },
            (err) => console.log(err),
        );
    };

    const getPics = async()=>{
        const imgArr = []
        const imgRef = ref(storage, 'pictures');

        try{
            const imgRes = await listAll(imgRef);
            
            for (const imageRef of imgRes.items) {
                const downloadImgURL = await getDownloadURL(imageRef);
                imgArr.push([downloadImgURL, imageRef.fullPath])
            }

            setPicturesData(imgArr)
        } catch(error){
            console.log(error)
        }
    }

    const handleDelete = (path)=>{
        const deleteRef = ref(storage, path);

        deleteObject(deleteRef).then(()=>{
            setUploaded(prevState => !prevState)
        }).catch((error)=>{console.log(error)});
    }

    const getURLsDatabase = async ()=>{  //get video urls from database
        const docArrays = []
        const videoRef = collection(db, 'video');

        const docSnapVideo = await getDocs(videoRef);
        docSnapVideo.forEach((doc)=>{
          const data = doc.data();
          const {url, poster, position } = data;
          docArrays.push([url, poster, position])
        })
        setVideoArrState(docArrays)
      }

    const collectionQuery = async(videoUrl, posterURL)=>{
        const collectionRef = collection(db, "video");
        const q = query(
          collectionRef,
          where('url', '==', videoUrl),
          where('poster', '==', posterURL),
        );
      
        try {
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            return querySnapshot.docs[0].id;
          } else {
            return null; // Return null if no matching document is found
          }
        } catch (error) {
          console.error('Error getting documents: ', error);
          return null;
        }
    }
    
    const handlePosChange = event =>{
        setNewPos(event.target.value);
    }
    const handleNewVideoPosChange = event =>{
        setNewVideoPos(event.target.value);
    }
    const handleNewVideoChange = event =>{
        setNewVideoURL(event.target.value);
    }
    const handleNewPosterChange = event =>{
        setNewPosterURL(event.target.value);
    }

    const handlePosSubmit = async(videoUrl, posterURL) =>{
        const docID = await collectionQuery(videoUrl, posterURL);
        if (docID) {
          await updateDoc(doc(db, 'video', docID), {
            position: parseInt(newPos)
          });
        }
        setUploaded(prevState => !prevState)
    }
    const handleVideoDel = async(videoUrl, posterURL) =>{
        const docID = await collectionQuery(videoUrl, posterURL);
        if (docID) {
            await deleteDoc(doc(db, 'video', docID))
          }
          setUploaded(prevState => !prevState)
    }

    const handleVideoUpload=(e)=>{
        e.preventDefault()
        const collectionRef = collection(db, 'video');

        const newDocData = {
            position: parseInt(newVideoPos),
            url: `${newVideoURL}`,
            poster: `https://drive.lienuc.com/uc?id=${newPosterURL}`
          };
          addDoc(collectionRef, newDocData)
          setUploaded(prevState => !prevState)
          setNewVideoPos('')
          setNewPosterURL('')
          setNewVideoURL('')
    }

    useEffect(()=>{
        if(percent== 100){
            setTimeout(() => {
                setUploaded(prevState => !prevState)
                setPercent('');
                setFile('')
              }, 3000);
        }
    }, [percent])

    useEffect(()=>{
        getPics();
        getURLsDatabase()
    },  [uploaded])


    return(
        <div id="admin-gallery-section">
            <div className="upload-div">
                <input type="file" onChange={handleFileChange} accept="/image/*" id="img-input"/>
                <button onClick={handleUpload} className="upload-btn">Kép feltöltése</button>
                {percent? <p>{percent}%</p>: ""} 
            </div>
            <div className="pictures-div">
                {picturesData.map((imgData, index) => (
                    <div className="pic-div" key={index}>
                          <img
                            src={imgData[0]}
                            className="admin-gallery-image"
                            alt={`Image ${index}`}
                            width='75px'
                          />
                          <p className="admin-gallery-path">{imgData[1]}</p>
                          <button onClick={()=>handleDelete(imgData[1])} className="admin-gallery-btn">Törlés</button>
                    </div>
                ))}
            </div>
            <div className="upload-div">
                <form action="POST" id="upload-form">
                    <label htmlFor="videoURL">Video ID</label>
                    <input type="text" id="videoURL" onChange={handleNewVideoChange} required value={newVideoURL}/>
                    <label htmlFor="posterURL">Index kép ID</label>
                    <input type="text" id="posterURL" onChange={handleNewPosterChange} required value={newPosterURL}/>
                    <label htmlFor="videoPos">Pozíció</label>
                    <input type="number" id="videoPos" onChange={handleNewVideoPosChange} required value={newVideoPos}/>
                    <button type="submit" className="upload-btn" onClick={handleVideoUpload}>Feltölt</button>
                </form>
            </div>
            <div className="pictures-div">
            {videoArrState.sort((a, b) => a[2] - b[2]).map((videoData, index) => (
                    <div className="pic-div" key={index}>
                          <img
                            src={videoData[1]}
                            crossorigin="anonymous"
                            className="admin-gallery-image"
                            alt={`Image ${index}`}
                            width='150px'
                          />
                          <p className="admin-gallery-path">Pozíció: {videoData[2]}</p>
                          <div>
                            <input type="number" value={newPos} onChange={handlePosChange}/>
                            <button className="admin-gallery-btn" style={{borderColor: "green", color: "green"}} onClick={()=>handlePosSubmit(videoData[0], videoData[1])}>Módosít</button>
                            <button className="admin-gallery-btn" onClick={()=> handleVideoDel(videoData[0], videoData[1])}>Törlés</button>
                          </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AdminGallery