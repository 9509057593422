import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div id='not-found'>
      <h1 id='404-header'>Úgy látszik eltévedtél!</h1>
      <Link to="/" id='link'>Portfólió megtekintése</Link>
    </div>
  );
}

export default NotFound;