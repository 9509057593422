import { Slide } from "react-awesome-reveal";

function Review (){

    return(
        <div id="review-section">
            <Slide direction="up" className="review-slide">
                <div className="review-text-container" > 
                    <p className="review-text">"Nagyon szuper lett a videó, minden pillanatot sikerült megragadnod és a zenét is nagyon jól eltaláltad. Még egyszer nagyon szépen köszönjük a munkádat."</p>
                    <p className="review-name">- ZsuZsi & Álmos</p>
                </div>
            </Slide>
            <Slide direction="up" className="review-slide">
                <div className="review-text-container"> 
                    <p className="review-text">"Jó volt újra átélni a pillanatokat! Nagyon jó videó lett, köszönjük."</p>
                    <p className="review-name">- Petra & Attila</p>
                </div>
            </Slide>
            <Slide direction="up" className="review-slide">
                <div className="review-text-container"> 
                    <p className="review-text">"A macskás ásítást (is) nagyon adom benne! 😃 Az egész videó teljesen visszahozta a nap hangulatát!"</p>
                    <p className="review-name">- Fanni & Gábor</p>
                </div>
            </Slide>
        </div>
    )
}

export default Review