import React from "react";
import { useEffect, useState } from "react";
import {app} from "../firebaseConf";
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
import AdminGallery from "../components/adminGallery";

function Admin () {
    const [loginEmail, setLoginEmail] = useState("");
    const [loginPass, setLoginPass] = useState("");
    const [loginError, setLoginError] = useState(null);
    const [uid, setUid] = useState(null)

    const handlelogin = async () =>{
        
        try {
            const userCredential = await signInWithEmailAndPassword(getAuth(app), loginEmail, loginPass);
            setUid(userCredential.user.uid)
        }
        catch (error){
            if(error.code == "auth/user-not-found"){
                setLoginError("Helytelen email!")
            }else if(error.code == "auth/wrong-password"){
                setLoginError("Rossz jelszó!")
            }else if(error.code == "auth/internal-error"){
                setLoginError("Nem adtál meg jelszót!")
            }else if(error.code == "auth/invalid-email"){
                setLoginError("Nem adtál meg email-t!")
            }else{
                setLoginError(error.code)
            }
            
        }
    }

    const handleSubmit = (event) =>{
        setLoginError(null)
        event.preventDefault();
        handlelogin();        
    }

    return(
        <div id="adminpage">
            {uid ?
                <AdminGallery/>
            :
                <form id="login-form" onSubmit={handleSubmit}>
                    <h1 className="form-h1">Welcome Back!</h1>
                    <input type="email" name="lemail" id="lemail" required placeholder="Email" value={loginEmail} onChange={(e)=>setLoginEmail(e.target.value)} />
                    <input type="password" name="lpsw" id="lpsw" required placeholder="Password" value={loginPass} onChange={(e)=>setLoginPass(e.target.value)}/>
                    <button type="submit" id="lbtn">Sign In</button>
                    {loginError && <p className="error-message">{loginError}</p>}
                </form>
            }
        </div>
    )
}

export default Admin