import { storage, db } from "../firebaseConf";
import {ref, listAll, getDownloadURL, getMetadata } from 'firebase/storage'
import {useState, useEffect} from "react";
import Close from "../media/close.svg"
import Next from "../media/next.svg"
import { Slide } from "react-awesome-reveal";
import playBtn from "../media/icon.png"
import { collection, getDocs } from "firebase/firestore";


function Gallery (){
    const  [imgArrState, setImgArrState] = useState([])
    const  [videoArrState, setVideoArrState] = useState([])
    const [visibleImages, setVisibleImages] = useState(6);
    const [additionalImages, setAdditionalImages] = useState(3);
    const [showLoadMoreButton, setShowLoadMoreButton ] = useState(false);
    const [activeImageURL, setActiveImageURL] = useState(null);
    const [activeVideoURL, setActiveVideoURL] = useState(null);
    const [overlayRender, setOverlayRender] = useState(false);

    useEffect (()=>{
        getArr();
        getURLsDatabase();
    }, [])

    const getArr  = async() =>{ //get the pictures from firebase
        const imgArr = []
        const imgRef = ref(storage, 'pictures');

        try{
            const imgRes = await listAll(imgRef);
            
            for (const imageRef of imgRes.items) {

                const downloadImgURL = await getDownloadURL(imageRef);
                imgArr.push(downloadImgURL)
            }
            
            if(visibleImages < imgArr.length){
                setShowLoadMoreButton(true)
            }
            else{
                setShowLoadMoreButton(false)
            }

            setImgArrState(imgArr)
        } catch(error){
            console.log(error)
        }
        
    }
    const handleImageClick = (imgURL) => {
        setActiveImageURL(imgURL);
        setOverlayRender(true);
        document.documentElement.classList.add('no-scroll');
      };
      const handleVideoClick = (videoURL) => {
        setActiveVideoURL(videoURL);
        setOverlayRender(true);
        document.documentElement.classList.add('no-scroll');
      };
      const handleClose = () => {
        setActiveImageURL(null);
        setActiveVideoURL(null);
        setOverlayRender(false);
        document.documentElement.classList.remove('no-scroll');
      };

    const loadMoreImages = () => {
        const newVisibleImages = visibleImages + additionalImages;
        setVisibleImages(newVisibleImages);
        if (newVisibleImages >= imgArrState.length) {
            setShowLoadMoreButton(false)
        }
      };
      const handleNextClick = () => {
        if (activeImageURL !== null) {
            const currentIndex = imgArrState.findIndex((img) => img === activeImageURL);
            if (currentIndex !== -1) {
              const nextIndex = (currentIndex + 1) % imgArrState.length;
              const nextImageURL = imgArrState[nextIndex];
              setActiveImageURL(nextImageURL);
            }
          }else if (activeVideoURL !== null){

            const currentIndex = videoArrState.findIndex((video) => video[0] === activeVideoURL);
            if (currentIndex !== -1) {
              const nextIndex = (currentIndex + 1) % videoArrState.length;
              const nextVideoURL = videoArrState[nextIndex][0];
              setActiveVideoURL(nextVideoURL);
            }
          }
      };
      const handlePrevClick = () => {
        if (activeImageURL !== null) {
            const currentIndex = imgArrState.findIndex((img) => img === activeImageURL);
            if (currentIndex !== -1) {
              const prevIndex = (currentIndex - 1 + imgArrState.length) % imgArrState.length;
              const prevImageURL = imgArrState[prevIndex];
              setActiveImageURL(prevImageURL);
            }
          }else if(activeVideoURL !== null){
            const currentIndex = videoArrState.findIndex((video) => video[0] === activeVideoURL);
            if (currentIndex !== -1) {
              const prevIndex = (currentIndex - 1 + videoArrState.length) % videoArrState.length;
              const prevVideoURL = videoArrState[prevIndex][0];
              setActiveVideoURL(prevVideoURL);
            }
          }
      };

      const getURLsDatabase = async ()=>{  //get video urls from database
        const docArrays = []
        const videoRef = collection(db, 'video');

        const docSnapVideo = await getDocs(videoRef);
        docSnapVideo.forEach((doc)=>{
          const data = doc.data();
          const { url, poster, position } = data;
          docArrays.push([url, poster, position])
        })
        setVideoArrState(docArrays)
      }

    return(
        <div id="gallery-section">
                <div className="gallery-content">
                {videoArrState.sort((a, b) => a[2] - b[2]).map((video, index) => (
                    <Slide direction='up' key={index} className="slide-video" triggerOnce>
                      <div className="icont">
                      <iframe src={`https://player.vimeo.com/video/${video[0]}?portrait=0&byline=0&title=0`} frameborder="0" allow="autoplay; fullscreen " className="gallery-video"/>
                      </div>
    
                    </Slide>))
                }
                {imgArrState.slice(0, visibleImages).map((img, index) => (
                    <Slide direction='up' key={index} className="slide-image" triggerOnce>
                      <img
                        src={img}
                        className="gallery-image"
                        alt={`Portfolio image ${index}`}
                        onClick={() => handleImageClick(img)}
                      />
                      
                    </Slide>))
                }
                </div> 
            {showLoadMoreButton && (
                <button onClick={loadMoreImages} id="loadMoreBtn">Mutass még!</button>
                )} 
            {overlayRender && (
                <div id="overlay">
                    <div id="close-svg" className="svg"><img src={Close} alt="close button" width='30px' onClick={handleClose}/></div>
                    <div id="nextBtn" className="svg"> <img src={Next} alt="next button" width='50px'onClick={handleNextClick}/></div>
                    {activeImageURL && <img src={activeImageURL} alt="focus mode" id="active" />}
                    {activeVideoURL && <video  crossorigin="anonymous" src={activeVideoURL} autoPlay loop playsInline typeof=".mp4" id="active-video" controls></video>}
                    <div id="previousBtn" className="svg"> <img src={Next} alt="next button" width='50px'id="previousImg" onClick={handlePrevClick}/></div>
                </div>
            )} 
        </div>
    )
}

export default Gallery