import {ref, listAll, getDownloadURL } from 'firebase/storage'
import { storage } from "../firebaseConf";
import { useState, useEffect } from 'react';

function Welcome (){
    const [backgroundURL, setBackgroundURL ] = useState('')

    useEffect (()=>{
        getArr();
    }, [])

    const getArr  = async() =>{
        const listRef = ref(storage, 'background');

        try{
            const res = await listAll(listRef);
            for (const imageRef of res.items) {
                const downloadURL = await getDownloadURL(imageRef);
                setBackgroundURL(downloadURL)
            }
        } catch(error){
            console.log(error)
        }
        
    }

    return(
        <div id="welcome-section">
            <video autoPlay loop muted playsInline src={backgroundURL} id="welcome-video" typeof=".mp4">
            </video>
            <div id="welcome-container">
                <div className="topleft box"></div>
                <div className="topright box"></div>
                <div className="middle">
                    <div className="text-container">
                        <h1 className="welcome-header">All In Frame</h1>
                        <h2 className="welcome-sub-header">Esküvő & Rendezvény & Portré & Reklám</h2>
                    </div>
                </div>
                <div className="botleft box"></div>
                <div className="botright box"></div>
            </div>
        </div>
    )
}

export default Welcome