import Logo from "../media/logo.ico"


function Navbar (){
    const handleNavClick = (targetId) => {
        const targetSection = document.getElementById(targetId);
        if (targetSection) {
          targetSection.scrollIntoView({ behavior: 'smooth' });
        }
      };
    return (
        <div id="navbar">
            <div id="logo-container">
            <a onClick={()=>handleNavClick('welcome-section')}><img src={Logo} id="logo" alt="logo"></img></a>
            </div>
            <div id="menu-container">
                <ul id="menu-list">
                    <li><a onClick={()=>handleNavClick('gallery-section')}>Portfólio</a></li>
                    <li><a onClick={()=>handleNavClick('about-section')} >Rólam</a></li>
                    <li><a onClick={()=>handleNavClick('contact-section')}>Kapcsolat</a></li>
                </ul>
            </div>
        </div>
    )
}

export default Navbar