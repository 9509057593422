import React from 'react';
import ReactDOM from 'react-dom/client';
import Admin from './pages/admin'
import Default from './pages/default'
import NotFound from './pages/404'
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<Default/>} />
      <Route path='admin' element={<Admin/>}/>
      <Route path='*' element={<NotFound/>}/>
    </Routes>
  </BrowserRouter> 
);
