import '../main.css'
import Welcome from '../components/welcome'
import Gallery from '../components/gallery'
import Review from '../components/review'
import About from '../components/about'
import Contact from '../components/contact'
import Navbar from '../components/navbar'


function Default () {
   
    return(
        <div id="mainpage">
            <Navbar/>
            <Welcome/>   
            <Gallery/>
            <Review/>
            <About/>
            <Contact/>
        </div>
    )
}

export default Default