import { collection,getDocs } from "firebase/firestore";
import { db } from "../firebaseConf";
import { useState } from "react";
import youtube from "../media/youtube.svg"
import facebook from "../media/facebook.svg"
import insta from "../media/instagram.svg"


function Contact (){
    const [result, setResult] = useState('');
    const handleFormSubmit = async(e) =>{
        e.preventDefault();
        
        let API_KEY = ''
        const API_KEYRef = collection(db, 'API_KEY');

        const docSnapVideo = await getDocs(API_KEYRef);
        docSnapVideo.forEach((doc)=>{
          const data = doc.data();
          const { api_key} = data;
          API_KEY = api_key
        })

    const formData = new FormData(e.target);
    let object = {
      access_key: API_KEY,
      last_name: "",
      first_name: '',
      email: '',
      phone: '',
      message: '',
    };
    
    formData.forEach((value, key) => {
      object[key] += value;
    });
    
    let json = JSON.stringify(object);
    setResult("Kérlek várj...");
    
    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: json
      });

      let jsonResponse = await response.json();
      if (response.status === 200) {
        setResult("Sikeresen elküldve");
      } else {
        setResult(jsonResponse.message);
      }
    } catch (error) {
      setResult("Hiba történt!");
    }

    setTimeout(() => {
      setResult('');
    }, 5000);
    }

    return(
        <div id="contact-section">
            <div>
                <div id='form-slide'>
                    <div id="contact-text">
                        <h3>Üzenj Nekem!</h3>
                        <p>Töltsd ki a táblázatot, hogy felvegyük a kapcsolatot</p>
                    </div>
                    <form action="https://api.web3forms.com/submit" onSubmit={handleFormSubmit} method="POST" id="form">
                        <div id="form-name-container" className="form-container">
                            <div className="form-small-container">
                                <label htmlFor="lname">Vezetéknév</label>
                                <input type="text" placeholder="Kovács" id="lname" name="last_name" required className="form-input"/>
                            </div>
                            <div className="form-small-container">
                                <label htmlFor="fname">Keresztnév</label>
                                <input type="text" placeholder="János" id="fname" name="first_name" required className="form-input"/>
                            </div>
                        </div>
                        <div id="form-contact-container" className="form-container">
                            <div className="form-small-container">
                                <label htmlFor="email">Email cím</label>
                                <input type="email" placeholder="kovacsjanos@gmail.com" id="email" name="email"required className="form-input"/>
                            </div>
                            <div className="form-small-container">
                                <label htmlFor="phone">Telefonszám</label>
                                <input type="phone" placeholder="+36 (20/30/60/70) 1234-567" id="phone" name="phone" required className="form-input"/>
                            </div>
                        </div>
                        <div id="form-message-container" className="form-small-container">
                            <label htmlFor="message">Miben segíthetek?</label>
                            <textarea rows="5" name="message" id="message" placeholder="Szia Peti, szeretnék..." required className="form-input"></textarea>
                        </div>
                        <button type="submit" id="submit-btn">Küldés!</button>
                        <div id="form-load-message">
                            {result}
                        </div>
                    </form>
                    </div>
            </div>
            <div id="social-contact">
             <a href="https://www.youtube.com/@allinframe7092" target="_blank" rel="noopener noreferrer"><img src={youtube} width="50px" alt="Link to Youtube" /></a> 
             <a href="https://www.instagram.com/berenyipeter20/" target="_blank" rel="noopener noreferrer"><img src={insta} alt="Link to Instagram" width="50px"  /></a> 
             <a href="https://www.facebook.com/allinframeproductions" target="_blank" rel="noopener noreferrer"><img src={facebook} alt="Link to Facebook" width="50px"  /></a> 
            </div>
            <div id="copyright-container">
              © 2023 All In Frame Production
            </div>
        </div>
    )
}

export default Contact